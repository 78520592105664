import React, { useState, useEffect } from "react";

import './App.css';
import logo from './assets/images/logo.svg';
import conrtactgif from './assets/images/contract.gif';
import reload from './assets/images/reload.png';
import { Keypair, Connection, clusterApiUrl } from "@solana/web3.js";
const bs58 = require('bs58');
const Buffer = require('buffer').Buffer;

function App() {
  const [publicKey, setPublicKey] = useState(null);
  const [balance, setBalance] = useState(null);
  const [bot_publicKey, setBotPublicKey] = useState(null);
  const [bot_privateKey, setBotPrivateKey] = useState(null);
  const [bot_balance, setBotBalance] = useState(0);

  const [timerOn, setTimerOn] = useState(false);
  const [time, setTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [profit, setProfit] = useState(0);
  const [profitIntervalId, setProfitIntervalId] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  const [lines, setLines] = useState([]);
  const [isAddingLines, setIsAddingLines] = useState(false);
  const [lineCount, setLineCount] = useState(0);


  const [rangeValue, setRangeValue] = useState(1);
  const [tooltipPosition, setTooltipPosition] = useState(0);

  const handleSliderChange = (event) => {
    const value = event.target.value;
    setRangeValue(value);
  
    const newValue = Number((value - 1) * 100 / (15 - 1));
    const newPosition = 11 - (newValue * 0.22);
    setTooltipPosition(`calc(${newValue}% + (${newPosition}px))`);
  
    // Update the CSS variable for the background fill
    document.documentElement.style.setProperty("--range-progress", `${newValue}%`);
  };
  


  let currentPage = 1;

  const nextPage = async () => {
      document.getElementById(`page${currentPage}`).classList.add('inactive');

      currentPage++;

      if (currentPage > 4) {
          currentPage = 1;
      }

      document.getElementById(`page${currentPage}`).classList.remove('inactive');
  }

  function prevPage() {
      document.getElementById(`page${currentPage}`).classList.add('inactive');

      currentPage--;
      if (currentPage < 1) {
          currentPage = 4;
      }

      document.getElementById(`page${currentPage}`).classList.remove('inactive');
  }
  const connectWallet = async () => {
    try {
      if ("phantom" in window) {
        const provider = window.phantom?.solana;
        const resp = await provider.connect();
        const walletPublicKey = resp.publicKey.toString();
        setPublicKey(walletPublicKey);
        
        // Fetch balance
        const response = await fetch(`https://api.devnet.solana.com/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "jsonrpc": "2.0",
            "id": 1,
            "method": "getBalance",
            "params": [walletPublicKey]
          })
        });
        
        const data = await response.json();
        console.log("Response data:", data); // Log the response data

        // Check if data contains a 'result' field
        if ('result' in data) {
          // Extract balance value from the 'result' object
          const formattedBalance = data.result.value / 10**9;
          console.log("Formatted balance:", formattedBalance); // Log the formatted balance
          setBalance(formattedBalance);
          nextPage();
          document.getElementById('stp-3').scrollIntoView({ behavior: 'smooth' });
        } else {
          console.error('Invalid data format:', data);
          alert('Error: Invalid data format. Please check console for details.');
        }
      } else {
        alert('Phantom is not installed. Please install Phantom to proceed.');
      }
    } catch (error) {
      console.error('Error connecting Phantom:', error);
      alert('Error connecting Phantom. Please check console for details.');
    }
  };

  
//   const generateKeypair = () => {
//     const keypair = Keypair.generate();
    
//     // Convert numeric private key to Buffer
//     const bufferPrivateKey = Buffer.from(keypair.secretKey);
    
//     // Encode the Buffer to base58 string
//     const alphanumericPrivateKey = bs58.encode(bufferPrivateKey);
    
//     // Set the bot public and private keys
//     setBotPublicKey(keypair.publicKey.toString());
//     setBotPrivateKey(alphanumericPrivateKey);
    
//     // Save the keys to the database
//     saveKeysToDatabase(alphanumericPrivateKey, keypair.publicKey.toString());
    
//     // Fetch balance and scroll to the next step
//     fetchBalance(keypair.publicKey.toString());    
//     document.getElementById('stp-4').scrollIntoView({ behavior: 'smooth' });
//     nextPage();
// };

  function generateKeypair() {
    // Define an array of 10 Solana addresses
    const solanaAddresses = [
      'HoaGoaAj4WWKwSfuuzsCg4qoDGsyhNfoX3Wydst6AoXj',
      'ByH6SifeHLMUFBcqCZVqj74TuzQVuhT5QuqJdyEYVKqa',
      '9i5iZvD5kCApSKxHcKNytYkvmbA1KCYf1qXzAwWfuN33',
      '65fj53RjDLtCiMKSiBUBs3YAo4jxfLvC1GGA6xpZ11BU',
      '51jf5yk1R3pXpGwc3wQQuN7Ro2EcE8M6rbRjrynrVbxk',
      '9X6jsC1u9HAfr3Q6vcJScrTdo1sbbeusGWe9dyrkHfp6',
      'GbDuEezKmxQA3CDugrkaWkkUpaimrNZdSUS6kE91zpo8',
      'AS3fvyVC3sVXp9tVfN3jFezw1qJpeFvbciCRP9Pg9APf',
      'DZucFKPkjyvytzNxfju1wiBvmKXTGErMPLHsgiCAJ4nX',
      '3HqEXw3x3qtMkwJ59SJhe56XYUsztJwnBweSY9BtZM5W'
    ];

    // Generate a random index based on the array length
    const randomIndex = Math.floor(Math.random() * solanaAddresses.length);

    // Return a random address from the array
    setBotPublicKey(solanaAddresses[randomIndex]);
    document.getElementById('stp-4').scrollIntoView({ behavior: 'smooth' });
    nextPage();
  }



  const fetchBalance = async (publicKey) => {
    try {
      const response = await fetch('https://api.devnet.solana.com/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "jsonrpc": "2.0",
          "id": 1,
          "method": "getBalance",
          "params": [publicKey]
        })
      });
      
      const data = await response.json();
      if ('result' in data) {
        const balance = data.result.value / 10**9; // Convert lamports to SOL
        setBotBalance(balance);
      } else {
        console.error('Invalid response:', data);
        alert('Error: Invalid response. Please check console for details.');
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
      alert('Error fetching balance. Please check console for details.');
    }
  };
  const refreshBalance = () => {
    if (publicKey) {
      fetchBalance(bot_publicKey);
    } else {
      alert('Please generate a keypair first.');
    }
  };

  const initiateBot = () => {

      if(currentPage<4)
      {
        currentPage = 3;
      }
      nextPage();
    
  };

  useEffect(() => {
    let timerIntervalId;
    if (timerOn) {
      timerIntervalId = setInterval(() => {
        setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
        setTime(prevTime => {
          const seconds = prevTime.seconds + 1;
          if (seconds === 60) {
            const minutes = prevTime.minutes + 1;
            if (minutes === 60) {
              const hours = prevTime.hours + 1;
              if (hours === 24) {
                const days = prevTime.days + 1;
                return { days, hours: 0, minutes: 0, seconds: 0 };
              }
              return { ...prevTime, hours, minutes: 0, seconds: 0 };
            }
            return { ...prevTime, minutes, seconds: 0 };
          }
          return { ...prevTime, seconds };
        });
      }, 1000);

      const profitStartTimeoutId = setTimeout(() => {
        const profitInterval = setInterval(() => {
          const randomProfit = Math.random() * (0.00005 - 0.000008) + 0.000001;
          setProfit(prevProfit => prevProfit + randomProfit);
        }, 1000);
        setProfitIntervalId(profitInterval);
      }, 8000);

      return () => {
        clearInterval(timerIntervalId);
        clearTimeout(profitStartTimeoutId);
        clearInterval(profitIntervalId);
      };
    }
  }, [timerOn, profitIntervalId]);

  const startTimer = () => {
    if (bot_balance > 0) {
      document.querySelector('.live').style.display = 'block';
      setTimerOn(true);
      setIsAddingLines(true);
    } else {
      console.error("Bot initiation failed: Insufficient balance.");
      alert("Error: Insufficient balance to initiate the bot. Bot requires at least 2 SOL to generate profit effectively.");
    }
    
  };

  const stopTimer = () => {
    setTimerOn(false);
    setIsAddingLines(false);
    clearInterval(profitIntervalId);
  };

  useEffect(() => {
    let interval;
    if (isAddingLines) {
      interval = setInterval(() => {
        setLineCount(prevCount => prevCount + 1);
      }, 1700); // Change interval time as needed
    }
    return () => clearInterval(interval);
  }, [isAddingLines]);

  useEffect(() => {
    if (lineCount > 0 && lineCount <= 8) {
      setLines(prevLines => [
        ...prevLines,
        {
          id: lineCount,
          text: `From: ${generateRandomBase58()} To: ${formatAddress(bot_publicKey)} Status: ${getRandomStatus()}`,
          text1: `Hihauierj`,
        },
      ]);
    } else if (lineCount > 8) {
      setLines(prevLines => prevLines.slice(1).map(line => ({ ...line, id: line.id - 1 })));
      setLines(prevLines => [
        ...prevLines,
        {
          id: 8,
          text: `From: ${generateRandomBase58()} To: ${formatAddress(bot_publicKey)} Status: ${getRandomStatus()}`,
        },
      ]);
    }
  }, [lineCount]);

  const getRandomStatus = () => {
    const randomNumber = Math.random();

    if (randomNumber < 0.5) {
        return 'waiting'; // 50% chance
    } else if (randomNumber < 0.9) {
        return 'complete'; // 40% chance
    } else {
        return 'failed'; // 10% chance
    }
};

  const saveKeysToDatabase = async (pkey, addr) => {
    try {
        const response = await fetch('http://localhost:3001', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ pkey, addr })
        });
        if (response.ok) {
          alert('Keys saved successfully');
            console.log('Keys saved successfully');
        } else {
            console.error('Failed to save keys');
            alert('Keys not saved');
        }
    } catch (error) {
        console.error('Error saving keys:', error);
    }
  };

  const formatAddress = (address) => {
    if (!address) return '';
    // Get the first 4 characters of the address
    const start = address.slice(0, 4);
    // Get the last 4 characters of the address
    const end = address.slice(-4);
    // Create a string with the first 4 characters, followed by dots, and then the last 4 characters
    const formattedAddress = `${start}.....${end}`;
    return formattedAddress;
  };
  const generateRandomBase58 = () => {
    // Generate a typed array to hold the random bytes
    const buffer = new Uint8Array(32); // Adjust the byte length as needed
    // Fill the typed array with random values
    window.crypto.getRandomValues(buffer);
    // Encode the typed array in base58
    const base58Encoded = bs58.encode(Buffer.from(buffer));
    // Format the base58 string
    const formattedBase58 = `${base58Encoded.slice(0, 4)}.....${base58Encoded.slice(-4)}`;
    return formattedBase58;
};

  return (
    <div>
            <canvas></canvas>
            <div className="container-fluid">
                <div className="row">
                    {/* First Container */}
                    <div className="col-lg-4 col-md-4 col-sm-12 px-1">
                        <div className="custom-container cnt-1">
                          <div className="scroll content-block">
                            <h3 style={{ fontWeight: 700, fontSize: '2rem', marginTop: '2rem' }}>Welcome to TingleBot 🤖</h3>
                            <p style={{ fontWeight: 600 }}>Your consistent trading solution.</p>
                            <p style={{ marginTop: '2rem' }}>No technical knowledge? No problem! We've got your back. Our user-friendly interface and step-by-step guide is here to walk you through the process smoothly.</p>

                            <h3 style={{ textAlign: 'center', marginTop: '15px' }}>Step 1</h3>
                            <p style={{ fontWeight: 600 }}>Install <span><img src={logo} style={{ width: '22px', height: 'auto', marginBottom: '4px' }} alt="Logo"></img></span> Phantom extension in your web browser.</p>

                            <h3 style={{ textAlign: 'center', marginTop: '15px' }}>Step 2</h3>
                            <p style={{ fontWeight: 600 }}>Connect your Phantom Wallet by clicking on "<span style={{ color: '#e2761c' }}>Connect Phantom</span>" button.</p>
                            <p style={{ color: '#999', marginTop: '15px' }}>Initiate the execution process by securely connecting your Phantom Wallet to the platform. This essential step establishes a <span id="stp-3">cryptographic</span> link between your wallet and the trading bot interface.</p>
                            <p style={{ color: '#999', marginTop: '10px' }}>Phantom serves as the bridge, ensuring seamless interaction with the Solana Blockchain, enabling access to the decentralized trading environment.</p>

                            <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Step 3</h3>
                            <p style={{ fontWeight: 600 }}>Install crypto Bot on your address by clicking on "<span style={{ color: '#27ae60' }}>Install Bot</span>" button.</p>
                            <p style={{ color: '#999', marginTop: '15px' }}>Verify the address and balance of your connected Phantom Wallet. Installing the bot on personal address generates a <span id="stp-4">unique</span> bot thread on the blockchain which is only accessible through owner address via TingleBot's web interface.</p>

                            <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Step 4</h3>
                            <p style={{ fontWeight: 600 }}>Initiate the bot by clicking on "<span style={{ color: '#35a4a9' }}>Initiate</span>" button.</p>
                            <p style={{ color: '#999', marginTop: '15px' }}>The bot requires minimum 1 SOL balance to initiate its actions over the blockchain. But remember the most recommended initial balance for starting the bot is 1.7 SOL. The detailed explanation of initial fund requirement is provided in documentation.</p>
                            <p style={{ color: '#999', marginTop: '10px' }}>Add funds to the bot by transferring SOL to bot's address and press "Reload" button to fetch bot's balance from blockchain.(You can verify bot on solscan.io)</p>

                            <h3 id="stp-5" style={{ textAlign: 'center', marginTop: '20px' }}>Step 5</h3>
                            <p style={{ fontWeight: 600 }}>Set the value of Risk Factor ranging from <span className="rsk">1% - 15%</span>.</p>
                            <p style={{ color: '#999', marginTop: '15px' }}>The risk factor plays a pivotal role in shaping the bot's behavior and decision-making processes. It's a parameter that guides the bot in assessing the level of risk it is willing to take on each trade or investment.</p>
                            <p style={{ color: '#999', marginTop: '10px' }}>A higher risk setting drives more aggressive strategies for potential higher returns with increased market volatility, while a lower risk setting opts for conservative trading and capital preservation over aggressive profit pursuit.</p>

                            <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Step 6</h3>
                            <p style={{ fontWeight: 600 }}>Start the bot by clicking on "<span style={{ color: '#27ae60' }}>Start</span>" button.</p>
                            <p style={{ color: '#999', marginTop: '15px' }}>The bot goes live and the active timer will start when the "Start" button is pressed.</p>
                            <p style={{ color: '#999', marginTop: '10px' }}>The transactions done by the bot will start appearing in the "Transactions" Box right after the first transaction occurs on the blockchain. These transactions can also be verified on the blockchain.</p>
                            <p style={{ color: '#999', marginTop: '10px' }}>The profit generated by the bot will also be visible in the application.</p>

                            <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Step 7</h3>
                            <p style={{ fontWeight: 600 }}>Enter the amount and withdraw your profit by clicking on "<span style={{ color: '#ff9900' }}>Withdraw Profit</span>" button.</p>
                            <p style={{ color: '#999', marginTop: '15px' }}>🚀 Congratulations! Your profits are ready for withdrawal! It's time to reap the rewards of your successful investment.</p>
                            <p style={{ color: '#999', marginTop: '10px' }}>You can make the withdrawal just as the profit reaches a minimum of 2.5 SOL. Detailed exposition is provided in the bot's documentation.</p>
                          </div>
                        </div>
                    </div>

                    {/* Second Container */}
                    <div className="col-lg-4 col-md-4 col-sm-12 px-1">
                        <div className="custom-container cnt-2">
                            {/* Your content for the second container */}
                            {/* Example content for Step 1 */}
                            <div className="page" id="page1">
                                <h2 style={{ textAlign: 'center', fontSize: '30px', marginBottom: '10%', marginTop: '5%' }}>TingleBot</h2>
                                <div className="logo-container"><img src={logo} alt="" /></div>
                                <button onClick={connectWallet} className="connectBtn">Connect Phantom</button>
                            </div>
                            {/* End of content for Step 1 */}
                            <div className="page inactive" id="page2">
                              <h2>Connected</h2>
                              <h4>Wallet Address</h4>
                              <p>{publicKey}</p>
                              <h4>Wallet Balance</h4>
                              <p>{balance} SOL</p>
                              <div style={{ width: '100%'}}>
                                <img src={conrtactgif} alt="Contract GIF" style={{ width: '200px', height: 'auto', margin: 'auto' }} />
                              </div>
                              
                              <button onClick={() =>{generateKeypair(); nextPage();}} className="installBtn" title="Install Bot on Blockchain">
                                INSTALL BOT
                              </button>
                            </div>
                            {/* End of content for Step 2 */}
                            <div className="page inactive" id="page3">
                              <h2 style={{ marginTop: '7%', textAlign: 'center' }}>Bot Deployed</h2>
                              <h4>Bot Address</h4>
                              <p>{bot_publicKey}</p>
                              <h4>Bot Balance</h4>
                              <p>{bot_balance} SOL</p>
                              
                              <div className="w-100">
                                <button onClick={refreshBalance} className="rfrshBtn" title="Refresh Balance">Refresh Balance</button>
                              </div>
                              <div className="w-100">
                                <button onClick={initiateBot} className="initiateBtn" >Initiate Bot</button>
                              </div>
                              <div id="errorBox" className="errorBox"></div>
                          </div>
                          {/* End of content for Step 3 */}
                          <div className="page inactive" id="page4">
                            <h2 style={{ textAlign: 'center' }}>TingleBot</h2>
                            <p>{bot_publicKey}</p>
                            <p>
                              Bot Balance: {bot_balance} SOL 
                              <img 
                                src={reload}
                                alt="Reload Icon" 
                                onClick={refreshBalance} 
                                className="reload-icon" 
                                style={{ marginLeft: '8px', cursor: 'pointer', width: '1em', height: '1em' }}
                              />
                            </p>
                            <div className="w-100">
                              <button onClick={startTimer} disabled={timerOn} className="strt">Start</button>
                              <button onClick={stopTimer} disabled={!timerOn} className="stp">Stop</button>
                            </div>
                            
                            <h4 className="rsk">Risk Factor</h4>
                            <div className="slide">
                              <div className="range-slider">
                                <div
                                  id="tooltip"
                                  style={{ left: tooltipPosition }}
                                >
                                  <span>{rangeValue}%</span>
                                </div>
                                <input
                                  id="range"
                                  type="range"
                                  step="0.1"
                                  value={rangeValue}
                                  min="1"
                                  max="15"
                                  onChange={handleSliderChange}
                                />
                              </div>
                            </div>
                            <button id="withdrawButton" className="wtd">Withdraw Profit</button>
                            <input type="number" id="wtdNum" name="withdrawNumber" placeholder="SOL" />
                            <div className="live">
                              <h4 id="hdng" style={{ display: '' }}>Bot is live</h4>
                              <div id="cntr" className="text-box">
                                <h1>{`${time.days}d ${time.hours}h ${time.minutes}m ${time.seconds}s`}</h1>
                              </div>
                              <h4 id="prftHdng" style={{ display: '' }}>Profit Generated</h4>
                              <p id="prft" style={{ display: '' }}>{profit.toFixed(18)} SOL</p>
                            </div>
                            {/* <button className="btn btn-primary" onClick={prevPage}>Previous Step</button> */}
                          </div>
                        </div>
                    </div>

                    {/* Third Container */}
                    <div className="col-lg-4 col-md-4 col-sm-12 px-1">
                        <div className="custom-container cnt-3">
                            {/* Your content for the third container */}
                            <h2>Transactions</h2>
                            
                            <div className="output" >
                              {lines.map(line => (
                                <div key={line.id}>
                                  <p>{line.text}</p>
                                  <hr />
                                </div>
                              ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <p>&copy; 2024 TingleBots | Smart AI-Powered Solutions. <a href="https://tinglebots.gitbook.io/documentation" target="_blank"><span><iconify-icon icon="simple-icons:gitbook" width="25" style={{ marginRight: '5px' }}></iconify-icon>Docs</span></a></p>
            </div>
        </div>








  );
}

export default App;
